import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { IoLogoFacebook, IoLogoLinkedin } from "react-icons/io";
import LayoutContactUs from "../components/layouts/layout-contact-us";
import SEO from "../components/shared/seo";

export default class confirmation extends Component {
  render() {
    return (
      <LayoutContactUs>
        <SEO title="Contact us" />
        <Row noGutters className="">
          <Col lg={12} sm={12} className="thankyou ">

            <Row className="d-flex flex-column">
              <Col className="d-flex flex-column justify-content-center">
                <h3>Thanks for contacting us</h3>
                <p className="text-accent">We'll be in touch soon </p>
              </Col>
            </Row>

          </Col>

        </Row>
      </LayoutContactUs>
    );
  }
}
